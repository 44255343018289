import type { UseFetchOptions } from 'nuxt/app'

export function useStrapiSingleType<T>(
  url: string,
  options: UseFetchOptions<T> = {}
) {
  const config = useRuntimeConfig()
  const { strapiApiBaseURL, strapiApiKey } = config.public

  const defaults: UseFetchOptions<T> = {
    baseURL: strapiApiBaseURL,
    // cache request
    key: url,

    headers: {
      Authorization: `Bearer ${strapiApiKey}`,
    },
  }

  const params = { ...options, ...defaults }

  return useFetch(url, params)
}
